import React from "react";
import Banner from "../Banner/Banner";

class MiddleLayout0 extends React.Component {
  constructor(props) {
    super();
    const objBannerImages = {
      home: "upload/slider-1-bg.jpg",
      aboutus: "upload/about-us.jpg",
      enquiry: "upload/about-1-title-bg.jpg",
      services: "upload/about-1-title-bg.jpg",
      contactus: "upload/contact-us.jpg",
      track:"upload/about-1-title-bg.jpg"
    };
    const objPageHeaderTitle = {
      home: "",
      aboutus: "About us",
      enquiry: "Enquiry",
      services: "Services",
      contactus: "Contact us",
      track:"Track Your Shipment"
    };
    this.state = {
      bannerImage: "upload/slider-1-bg.jpg",
      pageHeader: "",
    };
    this.state.bannerImage = objBannerImages[props.page];
    this.state.pageHeader = objPageHeaderTitle[props.page];

    console.log(this.state.bannerImage, this.state);
  }

  render() {
    return (
      <div
        className="datex-app-page-wrapper "
        style={{ padding: "260px 0px 110px 0px" }}
        id="datex-wrapper-1"
      >
        <Banner bannerImage={this.state.bannerImage} />
        {this.props.page === "home" && (
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div
                className="datex-build-column datex-column-30 datex-column-first"
                id="datex-column-1"
              >
                <div
                  className="datex-build-column-content-margin datex-js "
                  style={{ padding: "190px 0px 30px 80px" }}
                >
                  <div className="datex-build-column-content clearfix datex-js ">
                    {/* <div className="datex-build-element">
                        <div
                          className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-bottom datex-item-pdlr"
                          style={{ paddingBottom: '35px' }}
                        >
                          <div className="datex-title-item-title-wrap ">
                            <h3
                              className="datex-title-item-title datex-skin-title "
                              style={{
                                fontSize: '40px',
                                fontWeight: '700',
                                letterSpacing: '0px',
                                textTransform: 'none',
                                color: '#ffffff',
                              }}
                            >
                              We Provide One Stop Logistic &amp; Warehousing
                              Services.
                              <span className="datex-title-item-title-divider datex-skin-divider"></span>
                            </h3>
                          </div>
                        </div>
                      </div> */}
                    {/* <div className="datex-build-element">
                        <div className="datex-button-item datex-item-pdlr datex-item-pdb datex-left-align">
                          <a
                            className="datex-button  datex-button-gradient datex-button-no-border"
                            href="/"
                            style={{
                              fontWeight: '800',
                              letterSpacing: '1px',
                              padding: '15px 34px 18px 34px',
                              borderRadius: '27px',
                              background: '#187542',
                            }}
                          >
                            <span className="datex-content">
                              Learn More
                            </span>
                          </a>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.page !== "home" && (
          <div className="datex-page-title-wrap datex-style-custom datex-center-align">
            <div
              className="logisco-header-transparent-substitute"
              style={{ height: "0px" }}
            ></div>
            <div className="datex-page-title-overlay"></div>
            <div className="datex-page-title-container datex-container">
              <div className="datex-page-title-content ">
                <h1
                  className="datex-page-title"
                  data-orig-font="51px"
                  style={{ fontSize: "40px" }}
                >
                  {this.state.pageHeader}
                </h1>
                {/* <div className="datex-page-caption">
                  We Have 1000+ Trucks in 120 Cities
                </div> */}
              </div>
            </div>
          </div>
        )}
        {/* {this.props.page === "home" && ( */}

        {/* )} */}
      </div>
    );
  }
}
export default MiddleLayout0;
