import React from 'react';

class MiddleLayout4 extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: '30px 0px 30px 0px' }}
        >
          <div
            className="datex-build-background-wrap"
            style={{ backgroundColor: '#f7f7f7' }}
          ></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div
              className="datex-app-page-wrapper-container clearfix datex-container-custom"
              style={{ maxWidth: '1280px' }}
            >
              <div
                className="datex-build-column datex-column-20 datex-column-first"
                data-skin="Homepage Column Service 2"
                id="datex-column-13"
              >
                <div
                  className="datex-build-column-content-margin datex-js"
                  data-sync-height="column svc datex"
                  data-sync-height-center
                  style={{
                    margin: '0px 26px 40px 0px',
                    padding: '55px 30px 50px',
                    height: '328px'
                  }}
                >
                  <div
                    className="datex-sync-height-pre-spaces"
                    style={{ paddingTop: '11.5px' }}
                  ></div>
                  <div
                    className="datex-build-background-wrap"
                    style={{ backgroundColor: '#ffffff' }}
                  ></div>
                  <div
                    className="datex-build-background-frame"
                    style={{
                      margin: '0px 0px 0px 0px',
                      borderWidth: '0px 0px 3px 0px',
                      borderStyle: 'solid',
                      borderColor: '#187542',
                    }}
                  ></div>
                  <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                    <div className="datex-build-element">
                      <div
                        className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                        style={{ paddingBottom: '0px' }}
                      >
                        <div
                          className="datex-column-service-media datex-media-image"
                        // style={{ marginTop: '8px', marginBottom: '26px' }}
                        >
                          <img
                            src="upload/road-transport.png"
                            alt=""
                            width="45px"
                            // width="47"
                            // height="44"
                            title="Road transport"
                          />
                        </div>
                        <div className="datex-column-service-content-wrapper">
                          <div className="datex-column-service-title-wrap">
                            <h3
                              className="datex-column-service-title datex-skin-title"
                              style={{
                                fontSize: '22px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                              }}
                            >
                              Road transport
                            </h3>
                          </div>
                          <div
                            className="datex-column-service-content"
                            style={{
                              fontSize: '16px',
                              fontWeight: '400',
                              textTransform: 'none',
                            }}
                          >
                            <p>
                              Speed, flexibility, and efficiency are the key in
                              the retail industry. That’s why numerous retailers
                              across the globe.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="datex-build-column-link"
                    href="/services"
                    target="_self"
                  ></a>
                </div>
              </div>
              <div
                className="datex-build-column datex-column-20"
                data-skin="Homepage Column Service 2"
                id="datex-column-14"
              >
                <div
                  className="datex-build-column-content-margin datex-js"
                  data-sync-height="column svc datex"
                  data-sync-height-center
                  style={{
                    margin: '0px 0px 13px 40px',
                    padding: '55px 30px',
                    height: '328px'
                  }}
                >
                  <div
                    className="datex-sync-height-pre-spaces"
                    style={{ paddingTop: '30.5px' }}
                  ></div>
                  <div
                    className="datex-build-background-wrap"
                    style={{ backgroundColor: '#ffffff' }}
                  ></div>
                  <div
                    className="datex-build-background-frame"
                    style={{
                      margin: '0px 0px 0px 0px',
                      borderWidth: '0px 0px 3px 0px',
                      borderStyle: 'solid',
                      borderColor: '#187542',
                    }}
                  ></div>
                  <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                    <div className="datex-build-element">
                      <div
                        className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                        style={{ paddingBottom: '0px' }}
                      >
                        <div
                          className="datex-column-service-media datex-media-image"
                        // style={{ marginTop: '10px', marginBottom: '25px' }}
                        >
                          <img
                            src="upload/air-transport.png"
                            alt=""
                            width="45px"
                            // width="38"
                            // height="40"
                            title="Air transport"
                          />
                        </div>
                        <div className="datex-column-service-content-wrapper">
                          <div className="datex-column-service-title-wrap">
                            <h3
                              className="datex-column-service-title datex-skin-title"
                              style={{
                                fontSize: '22px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                              }}
                            >
                              Air Transport
                            </h3>
                          </div>
                          <div
                            className="datex-column-service-content"
                            style={{ textTransform: 'none' }}
                          >
                            <p>
                              The reliable delivery of healthcare products can
                              be lifesaving. Which is why we develop supply
                              chain solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="datex-build-column-link"
                    href="/services"
                    target="_self"
                  ></a>
                </div>
              </div>
              <div
                className="datex-build-column datex-column-20"
                data-skin="Homepage Column Service 2"
                id="datex-column-15"
              >
                <div
                  className="datex-build-column-content-margin datex-js"
                  data-sync-height="column svc datex"
                  data-sync-height-center
                  style={{
                    margin: '0px 0px 60px 26px',
                    padding: '55px 30px 50px',
                    height: '328px'
                  }}
                >
                  <div
                    className="datex-sync-height-pre-spaces"
                    style={{ paddingTop: '28px' }}
                  ></div>
                  <div
                    className="datex-build-background-wrap"
                    style={{ backgroundColor: '#ffffff' }}
                  ></div>
                  <div
                    className="datex-build-background-frame"
                    style={{
                      margin: '0px 0px 0px 0px',
                      borderWidth: '0px 0px 3px 0px',
                      borderStyle: 'solid',
                      borderColor: '#187542',
                    }}
                  ></div>
                  <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                    <div className="datex-build-element">
                      <div
                        className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                        style={{ paddingBottom: '0px' }}
                      >
                        <div
                          className="datex-column-service-media datex-media-image"
                        // style={{ marginTop: '10px', marginBottom: '23px' }}
                        >
                          <img
                            src="upload/sea-transport.png"
                            alt=""
                            width="45px"
                            // width="23"
                            // height="41"
                            title="Sea transport"
                          />
                        </div>
                        <div className="datex-column-service-content-wrapper">
                          <div className="datex-column-service-title-wrap">
                            <h3
                              className="datex-column-service-title datex-skin-title"
                              style={{
                                fontSize: '22px',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                              }}
                            >
                              Sea Transport
                            </h3>
                          </div>
                          <div
                            className="datex-column-service-content"
                            style={{
                              fontSize: '16px',
                              fontWeight: '400',
                              textTransform: 'none',
                            }}
                          >
                            <p>
                              datex has an in-depth understanding of the
                              consumer industry and offers innovative.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="datex-build-column-link"
                    href="/services"
                    target="_self"
                  ></a>
                </div>
              </div>
              <div className="datex-build-element">
                <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-center-align">
                  <div
                    className="datex-text-box-item-content"
                    style={{ textTransform: 'none', color: '#929bab' }}
                  >
                    <p>
                      Get the best transport service to meet your requirement.{' '}
                      <a style={{ color: '#0e723b' }} href="/services">
                        Learn More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MiddleLayout4;
