import React from 'react';
import Layout from './components/Layout/Layout';
import Footer from './components/Footer/Footer';
import MobileHeader1 from './components/MobileHeader1/MobileHeader1';
import MobileHeader2 from './components/MobileHeader2/MobileHeader2';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import './App.css'
class App extends React.Component {
  constructor() {
    super();
    console.log(window.location);
    this.state = {
      width: 0,
      isMobileView: false,
      isMobileMenuOpen: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.setState({ width: window.innerWidth });
  }

  toggleMenu = () => {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen })
  }

  render() {
    console.log("width", this.state.width)
    const propsObj = { ...this.state, toggleMenu: this.toggleMenu }
    return (
      <Router>
        {this.state.width <= 1200 && <MobileHeader1 {...propsObj} />}
        {this.state.width <= 1200 && <MobileHeader2 {...propsObj} />}
        <div className="datex-body-outer-wrapper">
          <div className="datex-body-wrapper clearfix datex-with-transparent-header datex-with-frame">
            <Layout {...propsObj} />
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
