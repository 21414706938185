import React from 'react';

class MiddleLayout3 extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: '60px 0px 0px 0px' }}
          id="datex-wrapper-6"
        >
          <div
            className="datex-build-background-wrap"
            style={{ backgroundColor: '#f7f7f7' }}
          ></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div
              className="datex-app-page-wrapper-container clearfix datex-container-custom"
              style={{ maxWidth: '895px' }}
            >
              <div className="datex-build-element">
                <div className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-top datex-item-pdlr">
                  <span
                    className="datex-title-item-caption datex-info-font datex-skin-caption"
                    style={{
                      fontSize: '14px',
                      fontStyle: 'normal',
                      letterSpacing: '1px',
                      textTransform: 'uppercase',
                      color: '#9a9a9a',
                      marginBottom: '0px',
                    }}
                  >
                    We are expert in many industries
                  </span>
                  <div className="datex-title-item-title-wrap ">
                    <h3
                      className="datex-title-item-title datex-skin-title "
                      style={{
                        fontSize: '40px',
                        fontWeight: '700',
                        letterSpacing: '0px',
                        textTransform: 'none',
                      }}
                    >
                      Industry Solutions
                      <span className="datex-title-item-title-divider datex-skin-divider"></span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="datex-build-element">
                <div
                  className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-center-align"
                  style={{ paddingBottom: '20px' }}
                >
                  <div
                    className="datex-text-box-item-content"
                    style={{
                      fontSize: '16px',
                      textTransform: 'none',
                      color: '#757f90',
                    }}
                  >
                    <p>
                      As one of the leading providers of logistics solutions
                      across the globe, Logisco has an immense portfolio of
                      transport and contract logistics solutions that spans
                      multiple industries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MiddleLayout3;
