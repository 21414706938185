import React from "react";
import MiddleLayout0 from "../../components/MiddleLayout0/MiddleLayout0";

class ContactUs extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <MiddleLayout0 page="contactus"></MiddleLayout0>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: "45px 0px 35px 0px" }}
        >
          <div
            className="datex-build-background-wrap"
            style={{ backgroundColor: "#ffffff" }}
          ></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div className="datex-build-column datex-column-20 datex-column-first">
                <div
                  className="datex-build-column-content-margin datex-js "
                  style={{ padding: "50px 20px 0px 20px" }}
                >
                  <div
                    className="datex-build-column-content clearfix datex-js"
                    data-gdlr-animation-duration="600ms"
                    data-gdlr-animation-offset="0.8"
                  >
                    <div className="datex-build-element">
                      <div className="datex-icon-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <i
                          className=" datex-icon-item-icon fa fa-phone"
                          style={{
                            color: "#0e723b",
                            fontSize: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "23px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            Phone
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align"
                        style={{ paddingBottom: "0px" }}
                      >
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px" }}
                        >
                          <p>
                            We are reaching on phone almost 16 hours a day for
                            you.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px", textTransform: "none" }}
                        >
                          <p>
                            <a href="/">079-26405015</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20">
                <div
                  className="datex-build-column-content-margin datex-js "
                  style={{ padding: "50px 20px 0px 20px" }}
                >
                  <div
                    className="datex-build-column-content clearfix datex-js"
                    data-gdlr-animation-duration="600ms"
                    data-gdlr-animation-offset="0.8"
                  >
                    <div className="datex-build-element">
                      <div className="datex-icon-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <i
                          className=" datex-icon-item-icon fa fa-envelope-o"
                          style={{
                            color: "#0e723b",
                            fontSize: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "23px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            Email
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align"
                        style={{ paddingBottom: "0px" }}
                      >
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px" }}
                        >
                          <p>
                            You reach out to us or send an inquiry a one click
                            away from an email.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px" }}
                        >
                          <p>
                            <a href="mailTo:info@datexcargo.com;">
                              info@datexcargo.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20">
                <div
                  className="datex-build-column-content-margin datex-js "
                  style={{ padding: "50px 20px 0px 20px" }}
                >
                  <div
                    className="datex-build-column-content clearfix datex-js"
                    data-gdlr-animation-duration="600ms"
                    data-gdlr-animation-offset="0.8"
                  >
                    <div className="datex-build-element">
                      <div className="datex-icon-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <i
                          className=" datex-icon-item-icon fa fa-location-arrow"
                          style={{
                            color: "#0e723b",
                            fontSize: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "23px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            Location
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align"
                        style={{ paddingBottom: "0px" }}
                      >
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px" }}
                        >
                          <p>
                            No. 5, First Floor, Swastik House,
                            <br />
                            Near Income Tax Railway Crossing,
                            <br />
                            Stadium Road,
                            <br />
                            Navrangpura,
                            <br />
                            Ahmedabad – 380009
                            <br />
                            Gujarat
                            <br />
                            India
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: "16px" }}
                        >
                          <p>
                            <a href="/">View On Google Map</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: "85px 0px 20px 0px" }}
        >
          <div
            className="datex-build-background-wrap"
            style={{ backgroundColor: "#f3f3f3" }}
          ></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div className="datex-build-column datex-column-60 datex-column-first">
                <div className="datex-build-column-content-margin datex-js ">
                  <div
                    className="datex-build-column-content clearfix datex-js "
                    style={{ maxWidth: "760px" }}
                  >
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "45px" }}
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "39px",
                              letterSpacing: "0px",
                              textTransform: "none",
                            }}
                          >
                            Leave us your info{" "}
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                        <span
                          className="datex-title-item-caption datex-info-font datex-skin-caption"
                          style={{ fontSize: "19px", fontStyle: "normal" }}
                        >
                          and we will get back to you.
                        </span>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div className="datex-contact-form-7-item datex-item-pdlr datex-item-pdb ">
                        <div
                          role="form"
                          className="wpcf7"
                          id="wpcf7-f1979-p1964-o1"
                          lang="en-US"
                          dir="ltr"
                        >
                          {/* <form
                            className="quform"
                            action="/"
                            method="post"
                            enctype="multipart/form-data"
                            onclick=""
                          > */}
                          <div className="quform-elements">
                            <div className="quform-element">
                              <span className="wpcf7-form-control-wrap your-name">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  size="40"
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Name*"
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <span className="wpcf7-form-control-wrap your-email">
                                <input
                                  id="email"
                                  type="text"
                                  name="email"
                                  size="40"
                                  className="input1"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Email*"
                                />
                              </span>
                            </div>
                            <div className="quform-element">
                              <span className="wpcf7-form-control-wrap your-message">
                                <textarea
                                  id="message"
                                  name="message"
                                  cols="40"
                                  rows="10"
                                  className="input1"
                                  aria-invalid="false"
                                  placeholder="Message*"
                                ></textarea>
                              </span>
                            </div>
                            <div className="quform-submit quform-submit-inner2">
                              <div className="quform-submit-inner">
                                <button type="submit" className="submit-button">
                                  <span>Send</span>
                                </button>
                              </div>
                              <div className="quform-loading-wrap">
                                <span className="quform-loading"></span>
                              </div>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: "0px 0px 0px 0px" }}
        >
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-app-page-wrapper-full-no-space">
              <div className="datex-build-element">
                <div
                  className="datex-wp-google-map-plugin-item datex-item-pdlr datex-item-pdb "
                  style={{ paddingBottom: "0px" }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <iframe
                      style={{ width: "100%", height: "480px", border: "0" }}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d458.94590281985677!2d72.56559550295641!3d23.039657326760175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848ac448bc5f%3A0x7bdec2ea33320b8f!2sSwastik%20House%2C%20Navarangpura%20Gam%2C%20Navrangpura%2C%20Ahmedabad%2C%20Gujarat%20380014!5e0!3m2!1sen!2sin!4v1616591668593!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                    <div
                      style={{
                        position: "absolute",
                        width: "80%",
                        bottom: "20px",
                        left: "0",
                        right: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                        color: "#000",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
