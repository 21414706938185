import React from 'react';
import MiddleLayout0 from '../../components/MiddleLayout0/MiddleLayout0';

class AboutUs extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <MiddleLayout0 page="aboutus"></MiddleLayout0>
        <div>
          <div
            className="datex-app-page-wrapper "
            style={{ padding: '0px 0px 0px 0px' }}
          >
            <div className="datex-build-background-wrap">
              <div
                className="datex-build-background datex-parallax datex-js"
                style={{
                  backgroundImage: 'url("upload/about-1-globe-bg-1.jpg")',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  height: '881.6px',
                  transform: 'translate(0px, -59.8px)',
                }}
                data-parallax-speed="0.2"
              ></div>
            </div>
            <div className="datex-app-page-wrapper-content datex-js ">
              <div className="datex-app-page-wrapper-container clearfix datex-container">
                <div
                  className="datex-build-column datex-column-30 datex-column-first"
                  id="datex-column-1"
                >
                  <div
                    className="datex-build-column-content-margin datex-js "
                    style={{ padding: '110px 0px 40px 20px', height: '680px' }}
                    data-sync-height="height logis1"
                  >
                    <div className="datex-build-background-wrap">
                      <div
                        className="datex-build-background datex-parallax datex-js"
                        style={{
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          height: '1486.4px',
                          transform: 'translate(0px, -239.2px)',
                        }}
                        data-parallax-speed="0.8"
                      ></div>
                    </div>
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                      <div className="datex-build-element">
                        <div
                          className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                          style={{ paddingBottom: '60px' }}
                        >
                          <div className="datex-title-item-title-wrap ">
                            <h3
                              className="datex-title-item-title datex-skin-title "
                              style={{
                                fontSize: '32px',
                                letterSpacing: '0px',
                                textTransform: 'none',
                                color: '#0e723b',
                              }}
                            >
                              Our Mission
                              <span className="datex-title-item-title-divider datex-skin-divider"></span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="datex-build-element">
                        <div className="datex-icon-list-item datex-item-pdlr datex-item-pdb clearfix ">
                          <ul>
                            <li
                              className="datex-skin-divider"
                              style={{ marginBottom: '50px' }}
                            >
                              <span
                                className="datex-icon-list-image"
                                style={{ marginRight: '38px' }}
                              >
                                <img
                                  src="upload/clock-icon.png"
                                  alt=""
                                  width="50"
                                  height="59"
                                  title="clock-icon"
                                />
                              </span>
                              <div className="datex-icon-list-content-wrap">
                                <span
                                  className="datex-icon-list-content"
                                  style={{
                                    color: '#252525',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                  }}
                                >
                                  Fast Service
                                </span>
                                <span
                                  className="datex-icon-list-caption"
                                  style={{
                                    color: '#4f5d77',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                  }}
                                >
                                  A wonderful serenity has taken pos.session of
                                  my entire soul, like these sweet mornings of
                                  spring which I enjoy.
                                </span>
                              </div>
                            </li>
                            <li
                              className="datex-skin-divider"
                              style={{ marginBottom: '50px' }}
                            >
                              <span
                                className="datex-icon-list-image"
                                style={{ marginRight: '38px' }}
                              >
                                <img
                                  src="upload/paper-icon.png"
                                  alt=""
                                  width="49"
                                  height="57"
                                  title="paper-icon"
                                />
                              </span>
                              <div className="datex-icon-list-content-wrap">
                                <span
                                  className="datex-icon-list-content"
                                  style={{
                                    color: '#252525',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                  }}
                                >
                                  100% Accuracy
                                </span>
                                <span
                                  className="datex-icon-list-caption"
                                  style={{
                                    color: '#4f5d77',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                  }}
                                >
                                  A wonderful serenity has taken possession of
                                  my entire soul, like these sweet mornings of
                                  spring which I enjoy.
                                </span>
                              </div>
                            </li>
                            <li
                              className="datex-skin-divider"
                              style={{ marginBottom: '50px' }}
                            >
                              <span
                                className="datex-icon-list-image"
                                style={{ marginRight: '38px' }}
                              >
                                <img
                                  src="upload/paper2-icon.png"
                                  alt=""
                                  width="50"
                                  height="49"
                                  title="paper2-icon"
                                />
                              </span>
                              <div className="datex-icon-list-content-wrap">
                                <span
                                  className="datex-icon-list-content"
                                  style={{
                                    color: '#252525',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                  }}
                                >
                                  Safety &amp; Guarantee
                                </span>
                                <span
                                  className="datex-icon-list-caption"
                                  style={{
                                    color: '#4f5d77',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                  }}
                                >
                                  A wonderful serenity has taken possession of
                                  my entire soul, like these sweet mornings of
                                  spring which I enjoy.
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="datex-build-column datex-column-30"
                  id="datex-column-2"
                >
                  <div
                    className="datex-build-column-content-margin datex-js  datex-column-extend-right"
                    style={{ padding: '110px 50px 0px 70px', height: '680px' }}
                    data-sync-height="height logis1"
                  >
                    <div
                      className="datex-build-background-wrap"
                      style={{ backgroundColor: '#0e723b' }}
                    ></div>
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                      <div className="datex-build-element">
                        <div className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr">
                          <div className="datex-title-item-title-wrap ">
                            <h3
                              className="datex-title-item-title datex-skin-title "
                              style={{
                                fontSize: '32px',
                                letterSpacing: '0px',
                                textTransform: 'none',
                                color: '#ffffff',
                              }}
                            >
                              Who are we?
                              <span className="datex-title-item-title-divider datex-skin-divider"></span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="datex-build-element">
                        <div
                          className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align datex-no-p-space"
                          style={{ paddingBottom: '25px' }}
                        >
                          <div
                            className="datex-text-box-item-content"
                            style={{
                              fontSize: '20px',
                              fontWeight: '400',
                              textTransform: 'none',
                              color: '#a4c5ff',
                            }}
                          >
                            <p>
                            We are the 3rd supply chain provider party leading the expert logistics company to the best of our knowledge. We come together with the aim of helping our customers so that they can lean on us for delivering their goods to the right place. With our expertise in this field of 10 years, we have the platform to show our skills and adopt the agile environment. 
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="datex-build-element">
                        <div
                          className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align datex-no-p-space"
                          style={{ paddingBottom: '25px' }}
                        >
                          <div
                            className="datex-text-box-item-content"
                            style={{
                              fontSize: '17px',
                              fontWeight: '400',
                              textTransform: 'none',
                              color: '#ffffff',
                            }}
                          >
                            <p>
                              language ocean. A small river named Duden flows by
                              their place and supplies it with the necessary
                              regelialia. It is a paradisematic country, in
                              which roasted parts of sentences fly into your
                              mouth.
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="datex-app-page-wrapper "
            style={{ padding: '0px 0px 0px 0px' }}
          >
            <div className="datex-build-background-wrap">
              <div
                className="datex-build-background datex-parallax datex-js"
                style={{
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  height: '824px',
                  transform: 'translate(0px, -195.8px)',
                }}
                data-parallax-speed="0.2"
              ></div>
            </div>
            <div className="datex-app-page-wrapper-content datex-js ">
              <div className="datex-app-page-wrapper-container clearfix datex-container">
                <div className="datex-build-column datex-column-30 datex-column-first">
                  <div
                    className="datex-build-column-content-margin datex-js  datex-column-extend-left"
                    data-sync-height="height2"
                    style={{ height: '608px' }}
                  >
                    <div className="datex-build-background-wrap">
                      <div
                        className="datex-build-background datex-parallax datex-js"
                        style={{
                          backgroundImage: 'url(upload/why-we.jpg)',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          height: '824px',
                          transform: 'translate(0px, -195.8px)',
                        }}
                        data-parallax-speed="0.2"
                      ></div>
                    </div>
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content"></div>
                  </div>
                </div>
                <div
                  className="datex-build-column datex-column-30"
                  id="datex-column-3"
                >
                  <div
                    className="datex-build-column-content-margin datex-js "
                    style={{ padding: '100px 0px 10px 70px', height: '608px' }}
                    data-sync-height="height2"
                    data-sync-height-center=""
                  >
                    <div
                      className="datex-build-background-wrap"
                      style={{ backgroundColor: '#ffffff' }}
                    ></div>
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                      <div className="datex-build-element">
                        <div
                          className="datex-tab-item datex-js datex-item-pdb  datex-left-align datex-tab-style1-horizontal datex-item-pdlr"
                          style={{ paddingBottom: '50px' }}
                        >
                          <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content" style={{ color: '#838383' }}>
                            <div className="datex-build-element">
                              <div className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr">
                                <div className="datex-title-item-title-wrap ">
                                  <h3
                                    className="datex-title-item-title datex-skin-title "
                                    style={{
                                      fontSize: '32px',
                                      letterSpacing: '0px',
                                      textTransform: 'none',
                                      color: '#0e723b',
                                    }}
                                  >
                                    What we do?
                                    <span className="datex-title-item-title-divider datex-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="datex-build-element">
                              <div
                                className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align datex-no-p-space"
                                style={{ paddingBottom: '25px' }}
                              >
                                <div
                                  className="datex-text-box-item-content"
                                  style={{
                                    fontSize: '20px',
                                    fontWeight: '400',
                                    textTransform: 'none',
                                    color: '#4f5d77',
                                  }}
                                >
                                  <p>
                                  We stick to our commitments so we never emphasize things that we cannot make possible for you. We take pride to say that taking such a big responsibility was just a small step but we have come a long way in order to help the cargo and logistic industry.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="datex-build-element">
                              <div
                                className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align datex-no-p-space"
                                style={{ paddingBottom: '25px' }}
                              >
                                <div
                                  className="datex-text-box-item-content"
                                  style={{
                                    fontSize: '17px',
                                    fontWeight: '400',
                                    textTransform: 'none',
                                    color: '#4f5d77',
                                  }}
                                >
                                  <p>
                                    language ocean. A small river named Duden
                                    flows by their place and supplies it with
                                    the necessary regelialia. It is a
                                    paradisematic country, in which roasted
                                    parts of sentences fly into your mouth.
                                  </p>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
