import { React, Component } from 'react';

class MiddleLayout5 extends Component {
  constructor() {
    super();
    this.state = {
      awbNo: '',
    }
  }

  trackShipmentStatus = async (e) => {
    e.preventDefault();
    if (this.state.awbNo) {
      this.props.history.push('/tracking-status', this.state.awbNo)
    }
  }

  render() {
    return (
      <div
        className="datex-app-page-wrapper"
        style={{ padding: '0px 0px 0px 0px' }}
      >
        <div className="datex-build-background-wrap">
          <div
            className="datex-build-background datex-parallax datex-js"
            style={{
              backgroundImage: "url('upload/bg-tracking.jpg')",
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: '569.6px',
              transform: 'translate(0px, -88.8375px)',
            }}
            data-parallax-speed="0.2"
          ></div>
        </div>
        <div className="datex-app-page-wrapper-content datex-js ">
          <div className="datex-app-page-wrapper-container clearfix datex-container">
            <div className="datex-build-column datex-column-30 datex-column-first">
              <div className="datex-build-column-content-margin datex-js ">
                <div className="datex-build-column-content clearfix datex-js "></div>
              </div>
            </div>
            <div
              className="datex-build-column datex-column-30"
              data-skin="Re Button 2"
              id="datex-column-4"
            >
              <div
                className="datex-build-column-content-margin datex-js  datex-column-extend-right"
                style={{
                  margin: '0px 0px 0px 0px',
                  padding: '90px 25px 65px 60px',
                }}
              >
                <div
                  className="datex-build-background-wrap"
                  style={{
                    backgroundColor: '#0e723b',
                    borderRadius: '3px 0px 0px 0px',
                    background:
                      'linear-gradient(rgba(20, 51, 105, 1), rgba(20, 51, 105, 0.9))',
                  }}
                ></div>
                <div
                  className="datex-build-column-content clearfix datex-js"
                  data-gdlr-animation-duration="600ms"
                  data-gdlr-animation-offset="0.8"
                >
                  <div className="datex-build-element">
                    <div
                      className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                      style={{ paddingBottom: '25px' }}
                    >
                      <div
                        className="datex-title-item-left-image datex-media-image"
                        style={{ margin: '-2px 25px 0px 0px' }}
                      >
                        <img
                          src="upload/icon-box.png"
                          alt=""
                          width="34"
                          height="45"
                          title="icon-box"
                        />
                      </div>
                      <div className="datex-title-item-left-image-wrap">
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: '30px',
                              fontWeight: '700',
                              letterSpacing: '0px',
                              textTransform: 'none',
                              color: '#ffffff',
                            }}
                          >
                            Quick Tracking solution
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="datex-build-element">
                    <div
                      className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align"
                      style={{ paddingBottom: '10px' }}
                    >
                      <div
                        className="datex-text-box-item-content"
                        style={{
                          fontSize: '17px',
                          textTransform: 'none',
                          color: '#9bb4e0',
                        }}
                      >
                        <p>
                          This is just an example of the custom HTML form. You
                          may need to link it to your third party app or use
                          third party html snippet . This theme doesn’t offer
                          the tracking system.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="datex-build-element">
                    <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                      <div
                        className="datex-text-box-item-content"
                        style={{
                          fontSize: '15px',
                          textTransform: 'none',
                          color: '#c0cada',
                        }}
                      >
                        <p>
                          Do you want to get quick details for the cargo, where it reaches? Trace your items by filling in the tracking details. If you still getting any issues. Do get in touch with us at info@datexcargo.com.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="datex-build-element">
                    <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                      <div
                        className="datex-text-box-item-content"
                        style={{ textTransform: 'none' }}
                      >
                        <form className="datex-custom-tracking-form">
                          <div className="datex-custom-tracking-form-input">
                            <input
                              type="text"
                              placeholder="CL-XXX-XXX-XX"
                              style={{ background: '#fff' }}
                              onChange={(e) => this.setState({ awbNo: e.target.value })}
                            />
                          </div>
                          <div className="datex-custom-tracking-form-submit">
                            <input type="submit" value="Track Now!" onClick={this.trackShipmentStatus} />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiddleLayout5;
