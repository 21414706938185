import axios from 'axios'
import { API_URL } from '../constants/request.constant'

export const ApiRequest = (method, url, body) => {
  const options = {
    method,
    url:`${API_URL}${url}`,
  }
  if (body) {
    options.data = body
  }
  return axios(options)
}
