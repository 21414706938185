import React from 'react';

class MiddleLayout2 extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div
        className="datex-app-page-wrapper "
        style={{ margin: '0px 0px 0px 0px', padding: '115px 0px 120px 0px' }}
        id="datex-wrapper-3"
      >
        <div className="datex-build-background-wrap">
          <div
            className="datex-build-background datex-parallax datex-js"
            style={{
              backgroundImage: 'url(&quot;upload/hp-map-bg-2.jpg&quot;)',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: '624.2px',
              transform: 'translate(0px, -40.2px)',
            }}
            data-parallax-speed="0.2"
          ></div>
        </div>
        <div className="datex-app-page-wrapper-content datex-js ">
          <div className="datex-app-page-wrapper-container clearfix datex-container">
            <div
              className="datex-build-column datex-column-20 datex-column-first"
              data-skin="Homepage Column Service"
            >
              <div
                className="datex-build-column-content-margin datex-js "
                style={{ padding: '0px 10px 0px 10px' }}
              >
                <div
                  className="datex-build-column-content clearfix datex-js"
                // data-gdlr-animation="fadeInUp"
                // data-gdlr-animation-duration="600ms"
                // data-gdlr-animation-offset="0.8"
                >
                  <div className="datex-build-element">
                    <div
                      className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                      style={{ paddingBottom: '30px' }}
                    >
                      <div
                        className="datex-column-service-media datex-media-image"
                        style={{
                          maxWidth: '61px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <img
                          src="upload/icon-clock-n.png"
                          alt=""
                          width="100"
                          height="118"
                          title="icon-clock-n"
                        />
                      </div>
                      <div className="datex-column-service-content-wrapper">
                        <div className="datex-column-service-title-wrap">
                          <h3
                            className="datex-column-service-title datex-skin-title"
                            style={{
                              fontSize: '23px',
                              fontWeight: '700',
                              textTransform: 'none',
                            }}
                          >
                            On-time Delivery
                          </h3>
                        </div>
                        <div
                          className="datex-column-service-content"
                          style={{ fontSize: '16px', textTransform: 'none' }}
                        >
                          <p>
                            We promise timely delivery of the products without fail irrespective of the date and location.
                          </p>{' '}
                          <a
                            className="datex-column-service-read-more datex-info-font"
                            href="/"
                            target="_self"
                            style={{ fontStyle: 'normal' }}
                          >
                            {/* Learn More<i className="fa fa-long-arrow-right"></i> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="datex-build-column datex-column-20"
              data-skin="Homepage Column Service"
            >
              <div
                className="datex-build-column-content-margin datex-js "
                style={{ padding: '0px 10px 0px 10px' }}
              >
                <div
                  className="datex-build-column-content clearfix datex-js"
                // data-gdlr-animation="fadeInUp"
                // data-gdlr-animation-duration="600ms"
                // data-gdlr-animation-offset="0.8"
                >
                  <div className="datex-build-element">
                    <div
                      className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                      style={{ paddingBottom: '30px' }}
                    >
                      <div
                        className="datex-column-service-media datex-media-image"
                        style={{
                          maxWidth: '61px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <img
                          src="upload/icon-box-n.png"
                          alt=""
                          width="130"
                          height="104"
                          title="icon-box-n"
                        />
                      </div>
                      <div className="datex-column-service-content-wrapper">
                        <div className="datex-column-service-title-wrap">
                          <h3
                            className="datex-column-service-title datex-skin-title"
                            style={{
                              fontSize: '23px',
                              fontWeight: '700',
                              textTransform: 'none',
                            }}
                          >
                            Privacy Policy
                          </h3>
                        </div>
                        <div
                          className="datex-column-service-content"
                          style={{ fontSize: '16px', textTransform: 'none' }}
                        >
                          <p>
                            Privacy is a concern for all who gives their personal details. We understand it and make sure that there is no data breach of our clients.
                          </p>{' '}
                          <a
                            className="datex-column-service-read-more datex-info-font"
                            href="/"
                            target="_self"
                            style={{ fontStyle: 'normal' }}
                          >
                            {/* Learn More<i className="fa fa-long-arrow-right"></i> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="datex-build-column datex-column-20"
              data-skin="Homepage Column Service"
            >
              <div
                className="datex-build-column-content-margin datex-js "
                style={{ padding: '0px 10px 0px 10px' }}
              >
                <div
                  className="datex-build-column-content clearfix datex-js"
                // data-gdlr-animation="fadeInUp"
                // data-gdlr-animation-duration="600ms"
                // data-gdlr-animation-offset="0.8"
                >
                  <div className="datex-build-element">
                    <div
                      className="datex-column-service-item datex-item-pdb  datex-center-align datex-no-caption datex-item-pdlr"
                      style={{ paddingBottom: '30px' }}
                    >
                      <div
                        className="datex-column-service-media datex-media-image"
                        style={{
                          maxWidth: '61px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <img
                          src="upload/icon-support-n.png"
                          alt=""
                          width="120"
                          height="126"
                          title="icon-support-n"
                        />
                      </div>
                      <div className="datex-column-service-content-wrapper">
                        <div className="datex-column-service-title-wrap">
                          <h3
                            className="datex-column-service-title datex-skin-title"
                            style={{
                              fontSize: '23px',
                              fontWeight: '700',
                              textTransform: 'none',
                            }}
                          >
                            24/7 Support
                          </h3>
                        </div>
                        <div
                          className="datex-column-service-content"
                          style={{ fontSize: '16px', textTransform: 'none' }}
                        >
                          <p>
                            We know how it feels if you cannot get the trusted and reliable service from industries like logistics, where you spend money to deliver the goods in large quantities. We are always available for your help, irrespective of the time frame.
                          </p>{' '}
                          <a
                            className="datex-column-service-read-more datex-info-font"
                            href="/"
                            target="_self"
                            style={{ fontStyle: 'normal' }}
                          >
                            {/* Learn More<i className="fa fa-long-arrow-right"></i> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiddleLayout2;
