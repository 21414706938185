import React from 'react';
import Menu from '../Menu/Menu';

class TopHeader2 extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <header
        className="datex-header-wrap datex-header-style-plain datex-style-menu-right datex-sticky-navigation datex-style-slide"
        data-navigation-offset="75"
      >
        <div className="datex-header-background"></div>
        <div className="datex-header-container datex-header-full">
          <div className="datex-header-container-inner clearfix">
            <div className="datex-logo datex-item-pdlr">
              <div className="datex-logo-inner">
                <a className="" href="/">
                  <img
                    src="upload/logo-big.png"
                    alt=""
                    width="320"
                    height="84"
                    title="logo-big"
                  />
                </a>
              </div>
            </div>
            <Menu />
          </div>
        </div>
      </header>
    );
  }
}

export default TopHeader2;
