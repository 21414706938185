import React from "react";

class Banner extends React.Component {
  constructor(props) {
    super();
    // const objBannerImages = {
    //   home: 'upload/slider-1-bg.jpg',
    //   aboutus: 'upload/about-1-title-bg.jpg',
    //   enquiry: 'upload/about-1-title-bg.jpg',
    //   services: 'upload/about-1-title-bg.jpg',
    //   contactus: 'upload/about-1-title-bg.jpg',
    // };
    // const objPageHeaderTitle = {
    //   home: "",
    //   aboutus: 'About us',
    //   enquiry: 'Enquiry',
    //   services: 'Services',
    //   contactus: 'Contact us',
    // }
    // this.state = {
    //   bannerImage: 'upload/slider-1-bg.jpg',
    //   pageHeader: '',
    // };
    // this.state.bannerImage = objBannerImages[props.page];
    // this.state.pageHeader = objPageHeaderTitle[props.page];
    // console.log(this.props.bannerImage, this.props.pageHeader);
    console.log(props.bannerImage);
  }

  render() {
    return (
      <div className="datex-build-background-wrap">
        <div
          id="header-bg"
          className="datex-build-background datex-parallax datex-js"
          style={{
            backgroundImage: `url(${this.props.bannerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-parallax-speed="0"
        ></div>
      </div>
    );
  }
}
export default Banner;
