import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.scss';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.activeMenuClass = 'menu-highlighted-text-color';
    this.inactiveMenuClass = 'menu-regular-text-color';
  }

  render() {
    return (
      <div className="datex-navigation datex-item-pdlr clearfix ">
        <div
          className="datex-main-menu sf-js-enabled sf-arrows"
          id="datex-main-menu"
          style={{ touchAction: 'pan-y' }}
        >
          <ul id="menu-main-navigation-1" className="sf-menu">
            <li className={'menu-item page_item datex-normal-menu'}>
              <Link to="/">Home</Link>
            </li>
            <li className={'menu-item page_item datex-normal-menu'}>
              <Link to="/services">Services</Link>
            </li>
            <li className={'menu-item page_item datex-normal-menu'}>
              <Link to={{pathname: 'https://tracking.datexcargo.com'}} target="_blank">Track</Link>
            </li>
            <li className={'menu-item page_item datex-normal-menu'}>
              <Link to="/aboutus">About us</Link>
            </li>
            <li className={'menu-item page_item datex-normal-menu'}>
              <Link to="/contactus">Contact us</Link>
            </li>
            {/* <li className={'menu-item page_item datex-normal-menu'}>
              <Link to="/enquiry">Enquiry</Link>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default Menu;
