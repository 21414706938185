import React from 'react';
import MiddleLayout0 from '../../components/MiddleLayout0/MiddleLayout0';
import MiddleLayout1 from '../../components/MiddleLayout1/MiddleLayout1';
import MiddleLayout2 from '../../components/MiddleLayout2/MiddleLayout2';
import MiddleLayout3 from '../../components/MiddleLayout3/MiddleLayout3';
import MiddleLayout4 from '../../components/MiddleLayout4/MiddleLayout4';
import MiddleLayout5 from '../../components/MiddleLayout5/MiddleLayout5';

class Home extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div>
        <MiddleLayout0 page="home"></MiddleLayout0>
        <MiddleLayout1></MiddleLayout1>
        <MiddleLayout2></MiddleLayout2>
        <MiddleLayout3></MiddleLayout3>
        <MiddleLayout4></MiddleLayout4>
        <MiddleLayout5 {...this.props}></MiddleLayout5>
      </div>
    );
  }
}

export default Home;
