import React from 'react';

class MiddleLayout1 extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <div
          className="datex-app-page-wrapper"
          style={{ margin: '-60px 0px 0px 0px', padding: '0px 0px 0px 0px' }}
          id="datex-wrapper-2"
        >
          <div className="datex-build-background-wrap"></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div
                className=" datex-app-page-wrapper-container-inner datex-item-mglr clearfix"
                style={{
                  boxShadow: '0 0 35px rgba(10, 10, 10,0.12)',
                  backgroundColor: '#ffffff',
                  borderRadius: '3px 3px 3px 3px',
                  zIndex: '999',
                }}
              >
                <div className="datex-build-column datex-column-24 datex-column-first">
                  <div
                    className="datex-build-column-content-margin datex-js"
                    style={{ padding: '50px 0px 20px', height: '301px' }}
                    data-sync-height="map-height"
                  >
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                      <div className="datex-build-element">
                        <div className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align">
                          <div
                            className="datex-image-item-wrap datex-media-image  datex-image-item-style-rectangle"
                            style={{ borderWidth: '0px' }}
                          >
                            <img
                              src="upload/hp-1-map-1.jpg"
                              alt=""
                              width="444"
                              height="221"
                              title="hp-1-map"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="datex-build-column datex-column-36"
                  id="datex-column-3"
                >
                  <div
                    className="datex-build-column-content-margin datex-js "
                    style={{ padding: '50px 40px 0px 0px', height: '301px' }}
                    data-sync-height="map-height"
                  >
                    <div className="datex-build-column-content clearfix datex-js  datex-sync-height-content">
                      <div className="datex-build-element">
                        <div
                          className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                          style={{ paddingBottom: '25px' }}
                        >
                          <div className="datex-title-item-title-wrap ">
                            <h3
                              className="datex-title-item-title datex-skin-title "
                              style={{
                                fontSize: '31px',
                                fontWeight: '700',
                                letterSpacing: '0px',
                                textTransform: 'none',
                                color: '#187542',
                              }}
                            >
                              A worldclass service for customer
                              <span className="datex-title-item-title-divider datex-skin-divider"></span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="datex-build-element">
                        <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align datex-no-p-space">
                          <div
                            className="datex-text-box-item-content"
                            style={{
                              fontSize: '17px',
                              textTransform: 'none',
                              color: '#4f5d77',
                            }}
                          >
                            <p>
                              Datexcargo is one of the leading supply chains for expert logistics solutions across the globe. It represents the combined wisdom of young entrepreneurs who believe in providing full suite cargo and freight services to its customers. Our core focus relies on meeting the market demand by managing and coordinating with clients and vendors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MiddleLayout1;
