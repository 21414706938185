import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import TopHeader1 from '../TopHeader1/TopHeader1';
import TopHeader2 from '../TopHeader2/TopHeader2';
import Home from '../../pages/Home/Home';
import Service from '../../pages/Service/Service';
// import Tracking from '../../pages/Tracking/Tracking';
import AboutUs from '../../pages/About-us/About-us';
// import Enquiry from '../../pages/Enquiry/Enquiry';
import ContactUs from '../../pages/Contact-us/Contact-us';
import Tracking from '../../pages/Tracking/Tracking';

class Layout extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <>
        <div className="datex-header-background-transparent">
          {this.props.width > 1200 && <TopHeader1 />}
          {this.props.width > 1200 && <TopHeader2 />}
        </div>
        <div className="datex-page-wrapper" id="datex-page-wrapper">
          <div className="datex-main-wrapper">
            <Route exact path="/" component={Home} />
            <Route path="/services" component={Service} />
            {/* <Route path="/tracking" component={Tracking} /> */}
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/tracking-status" component={Tracking} />
            {/* <Route path="/enquiry" component={Enquiry} /> */}
          </div>
        </div>
      </>
    );
  }
}

export default Layout;
