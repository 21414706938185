import React from 'react';
import TopHeader from '../MobileHeader2/MobileHeader2';

class MobileHeader1 extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        {/* <TopHeader></TopHeader> */}
        <div className="datex-mobile-header-wrap">
          <div
            className="datex-mobile-header datex-header-background datex-style-slide datex-sticky-mobile-navigation "
            id="datex-mobile-header"
          >
            <div className="datex-mobile-header-container datex-container clearfix">
              <div className="datex-logo  datex-item-pdlr">
                <div className="datex-logo-inner">
                  <a className="" href="/">
                    <img
                      src="upload/logo-big.png"
                      alt=""
                      width="320"
                      height="84"
                      title="logo-big"
                    />
                  </a>
                </div>
              </div>
              <div className="datex-mobile-menu-right">
                <div
                  className="datex-main-menu-search"
                  id="datex-mobile-top-search"
                >
                  <i className="fa fa-search"></i>
                </div>
                <div
                  className="datex-main-menu-cart"
                  id="datex-mobile-menu-cart"
                >
                  <i
                    className="fa fa-shopping-cart"
                    data-datex-lb="top-bar"
                  ></i>
                  {/* <span className="datex-top-cart-count">0</span> */}
                  <div
                    className="datex-lightbox-content-wrap datex-top-cart-content-wrap"
                    data-datex-lb-id="top-bar"
                  >
                    <i className="icon_close datex-lightbox-close"></i>
                    <div className="datex-top-cart-title">
                      You have{' '}
                      <span className="datex-highlight">0 items</span> in your
                      cart
                    </div>
                    <div className="datex-top-cart-item-wrap">
                      <div className="datex-top-cart-item-divider"></div>
                      <div className="datex-top-cart-button-wrap datex-center-align">
                        <a
                          className="datex-top-cart-button-2 datex-button"
                          href=""
                        >
                          Go To Shopping
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="datex-mobile-menu">
                  <a
                    className="datex-mm-menu-button datex-mobile-menu-button datex-mobile-button-hamburger"
                    onClick={this.props.toggleMenu}
                  // href="#datex-mobile-menu"
                  >
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileHeader1;
