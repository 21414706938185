import React from 'react';
import MiddleLayout0 from '../../components/MiddleLayout0/MiddleLayout0';
import { trackStatus } from '../../services/TrackingService';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/BarLoader";

class Tracking extends React.Component {
  constructor() {
    super();
    this.state = { trackDetail: [], awbNo: '', isLoading: false };
  }

  componentDidMount() {
    console.log(this.props.location.state)
    let awbNo = '';
    if (this.props.location && this.props.location.state) {
      awbNo = this.props.location.state;
    }

    this.setState({ awbNo }, () => {
      this.trackShipmentStatus();
    })
  }

  trackShipmentStatus = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (this.state.awbNo) {
      this.setState({ trackDetail: null, isLoading: true })
      try {
        const status = await trackStatus(this.state.awbNo);
        this.setState({ isLoading: false })
        if (status && status.data) {
          console.log(status.data)
          this.setState({ trackDetail: status.data })
        } else {
          this.setState({ trackDetail: {} })
        }
      } catch (e) {
        this.setState({ trackDetail: {}, isLoading: false })
      }
    }
  }

  render() {
    return (
      <div>


        <MiddleLayout0 page="track"></MiddleLayout0>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: '80px 0px 30px 0px' }}
        >
          <div className="datex-build-background-wrap"></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div className="datex-build-column datex-column-30 datex-column-first">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                        style={{ paddingBottom: '20px' }}
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: '28px',
                              fontWeight: '700',
                              letterSpacing: '0px',
                              textTransform: 'none',
                              color: '#0e723b',
                            }}
                          >
                            Track your order
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-left-align">
                        <div
                          className="datex-text-box-item-content"
                          style={{ fontSize: '17px', textTransform: 'none' }}
                        >
                          <p>
                            Please enter the tracking order, to know latest tracking details.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="datex-build-column datex-column-30"
                data-skin="Re Button 2"
                id="datex-column-17"
              >
                <div
                  className="datex-build-column-content-margin datex-js "
                  style={{ padding: '40px 0px 0px 0px' }}
                >
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div className="datex-newsletter-item datex-item-pdlr datex-item-pdb  datex-style-rectangle">
                        <div className="newsletter newsletter-subscription">
                          <form
                            className="datex-newsletter-form clearfix"
                            method="post"
                            action="#"
                          // onSubmit="return newsletter_check(this)"
                          >
                            <div className="datex-item-pdlr">
                              <form className="datex-custom-tracking-form">
                                <div className="datex-custom-tracking-form-input ">
                                  <input
                                    type="text"
                                    placeholder="CL-XXX-XXX-XX"
                                    style={{
                                      background: '#fff',
                                      borderColor: '#d7d7d7',
                                      border: '1px solid',
                                    }}
                                    value={this.state.awbNo}
                                    onChange={(e) => this.setState({ awbNo: e.target.value })}
                                  />
                                </div>
                                <div className="datex-custom-tracking-form-submit">
                                  <input type="submit" value="Track Now!" onClick={this.trackShipmentStatus} />
                                </div>
                              </form>
                            </div>
                            {/* <div className="datex-newsletter-submit">
                              <input
                                className="newsletter-submit"
                                type="submit"
                                value="Subscribe"
                              />
                            </div> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="datex-title-item datex-item-pdb clearfix  datex-left-align datex-title-item-caption-top datex-item-pdlr"
                style={{ paddingBottom: '20px' }}
              >
                <div className="datex-title-item-title-wrap ">
                  <h3
                    className="datex-title-item-title datex-skin-title "
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      letterSpacing: '0px',
                      textTransform: 'none',
                      color: '#0e723b',
                    }}
                  >
                    Shipment information
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                  </h3>
                </div>
              </div> */}
              {this.state.isLoading && <div className="loader">
                <ClipLoader color={'#0e723b'} loading={true}
                  // css={override} 
                  size={80}
                />
              </div>}
              {this.state.trackDetail && this.state.trackDetail.BookingDate && <div
              // className="datex-build-column datex-column-30"
              >
                <table className="border-spacing-0">
                  <tr>
                    <td className="datex-right-align padding-right-">AWB NO. : </td>
                    <td className="datex-left-align padding-left-25 ">{this.state.trackDetail.AWBNo}</td>
                  </tr>
                  <tr>
                    <td className="datex-right-align">Booking Date : </td>
                    <td className="datex-left-align padding-left-25">{this.state.trackDetail.BookingDate}</td>
                  </tr>
                  <tr>
                    <td className="datex-right-align">Sender Name : </td>
                    <td className="datex-left-align padding-left-25">{this.state.trackDetail.SenderName}</td>
                  </tr>
                  <tr>
                    <td className="datex-right-align">Receiver Name : </td>
                    <td className="datex-left-align padding-left-25">{this.state.trackDetail.ReceiverName}</td>
                  </tr>
                  <tr>
                    <td className="datex-right-align">Destination :</td>
                    <td className="datex-left-align padding-left-25">{this.state.trackDetail.Destination}</td>
                  </tr>
                  <tr>
                    <td className="datex-right-align">Forwarding Number :</td>
                    <td className="datex-left-align padding-left-25"><a href={this.state.trackDetail.TrackURL} target="_blank">{this.state.trackDetail.TrackingNumber}</a></td>
                  </tr>
                </table>
              </div>}

              {this.state.trackDetail && this.state.trackDetail.BookingDate && <div>
                <table style={{ width: '100%' }}>
                  <tr style={{ width: '100%' }}>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Location</th>
                    <th>Status</th>
                  </tr>
                  {this.state.trackDetail && this.state.trackDetail.TrackDetail && this.state.trackDetail.TrackDetail.map((t) => {
                    return <tr>
                      <td>{t.mDate}</td>
                      <td>{t.mTime}</td>
                      <td>{t.mLocation}</td>
                      <td className="text-left">{t.mStatus}</td>
                    </tr>
                  })}
                </table>
              </div>}
              {!(this.state.trackDetail && this.state.trackDetail.BookingDate) && !this.state.isLoading && this.state.awbNo !== '' && <div>No result found</div>}
            </div>
          </div>

        </div>

      </div >
    );
  }
}

export default Tracking;
