import React from "react";
import MiddleLayout0 from "../../components/MiddleLayout0/MiddleLayout0";

class Service extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <MiddleLayout0 page="services"></MiddleLayout0>
        <div
          className="datex-app-page-wrapper "
          style={{ padding: "60px 0px 0px 0px" }}
          id="datex-wrapper-6"
        >
          <div
            className="datex-build-background-wrap"
            style={{ backgroundColor: "#f7f7f7" }}
          ></div>
          <div className="datex-app-page-wrapper-content datex-js ">
            <div className="datex-app-page-wrapper-container clearfix datex-container">
              <div className="datex-build-element">
                <div
                  className="datex-text-box-item datex-item-pdlr datex-item-pdb datex-center-align"
                  style={{ paddingBottom: "20px" }}
                >
                  <div
                    className="datex-text-box-item-content"
                    style={{
                      fontSize: "16px",
                      textTransform: "none",
                      color: "#757f90",
                    }}
                  >
                    <p>
                      We believe in fulfilling client requirements with our
                      expert logistics services to meet the comprehensive demand
                      of the market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20 datex-column-first">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div
                          className="datex-image-item-wrap datex-media-image  datex-image-item-style-round"
                          style={{ borderWidth: "0px" }}
                        >
                          <a href="javascript:void(0);">
                            <img
                              src="upload/land-transport.jpeg"
                              alt=""
                              width="700"
                              height="430"
                              title="Road Transport"
                            />
                            <span className="datex-image-overlay ">
                              <i className="datex-image-overlay-icon datex-size-28 fa fa-external-link"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "40px" }}
                        id="datex-title-item-id-38608"
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              target="_self"
                              style={{ color: "#0e723b" }}
                            >
                              Road Transport
                            </a>
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div
                          className="datex-image-item-wrap datex-media-image  datex-image-item-style-round"
                          style={{ borderWidth: "0px" }}
                        >
                          <a href="javascript:void(0);" target="_self">
                            <img
                              src="upload/air-transport.jpeg"
                              alt=""
                              width="700"
                              height="430"
                              title="Air Transport"
                            />
                            <span className="datex-image-overlay ">
                              <i className="datex-image-overlay-icon datex-size-28 fa fa-external-link"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "40px" }}
                        id="datex-title-item-id-52616"
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              target="_self"
                              style={{ color: "#0e723b" }}
                            >
                              Air Transport
                            </a>
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div
                          className="datex-image-item-wrap datex-media-image  datex-image-item-style-round"
                          style={{ borderWidth: "0px" }}
                        >
                          <a href="javascript:void(0);" target="_self">
                            <img
                              src="upload/ocean-transport.jpeg"
                              alt=""
                              width="700"
                              height="430"
                              title="Ocean Transport"
                            />
                            <span className="datex-image-overlay ">
                              <i className="datex-image-overlay-icon datex-size-28 fa fa-external-link"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "40px" }}
                        id="datex-title-item-id-39801"
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              target="_self"
                              style={{ color: "#0e723b" }}
                            >
                              Ocean Transport
                            </a>
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20 datex-column-first">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div
                          className="datex-image-item-wrap datex-media-image  datex-image-item-style-round"
                          style={{ borderWidth: "0px" }}
                        >
                          <a href="javascript:void(0);" target="_self">
                            <img
                              src="upload/warehouse.jpeg"
                              alt=""
                              width="700"
                              height="430"
                              title="Warehouse"
                            />
                            <span className="datex-image-overlay ">
                              <i className="datex-image-overlay-icon datex-size-28 fa fa-external-link"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "40px" }}
                        id="datex-title-item-id-90289"
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              target="_self"
                              style={{ color: "#0e723b" }}
                            >
                              Warehouse
                            </a>
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="datex-build-column datex-column-20">
                <div className="datex-build-column-content-margin datex-js ">
                  <div className="datex-build-column-content clearfix datex-js ">
                    <div className="datex-build-element">
                      <div
                        className="datex-image-item datex-item-pdlr datex-item-pdb  datex-center-align"
                        style={{ paddingBottom: "25px" }}
                      >
                        <div
                          className="datex-image-item-wrap datex-media-image  datex-image-item-style-round"
                          style={{ borderWidth: "0px" }}
                        >
                          <a href="javascript:void(0);" target="_self">
                            <img
                              src="upload/fragile.jpeg"
                              alt=""
                              width="700"
                              height="430"
                              title="Fragile Products"
                            />
                            <span className="datex-image-overlay ">
                              <i className="datex-image-overlay-icon datex-size-28 fa fa-external-link"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="datex-build-element">
                      <div
                        className="datex-title-item datex-item-pdb clearfix  datex-center-align datex-title-item-caption-bottom datex-item-pdlr"
                        style={{ paddingBottom: "40px" }}
                        id="datex-title-item-id-5860"
                      >
                        <div className="datex-title-item-title-wrap ">
                          <h3
                            className="datex-title-item-title datex-skin-title "
                            style={{
                              fontSize: "22px",
                              fontWeight: "700",
                              letterSpacing: "0px",
                              textTransform: "none",
                              color: "#0e723b",
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              target="_self"
                              style={{ color: "#0e723b" }}
                            >
                              Fragile Products
                            </a>
                            <span className="datex-title-item-title-divider datex-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
