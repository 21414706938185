import React from 'react';

class TopHeader1 extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className="datex-top-bar">
        <div className="datex-top-bar-background"></div>
        <div className="datex-top-bar-container datex-top-bar-full ">
          <div className="datex-top-bar-container-inner clearfix">
            <div className="datex-top-bar-left datex-item-pdlr">
              <div className="datex-dropdown-tab datex-js clearfix">
                <div className="datex-dropdown-tab-title">
                  <span className="datex-head">Ahmedabad</span>
                  {/* <div className="datex-dropdown-tab-head-wrap">
                    <div
                      className="datex-dropdown-tab-head datex-active"
                      data-index="0"
                    >
                      Ahmedabad
                    </div>
                    <div
                      className="datex-dropdown-tab-head "
                      data-index="1"
                    >
                      London
                    </div> 
                  </div> */}
                </div>
                <div className="datex-dropdown-tab-content-wrap">
                  <div
                    className="datex-dropdown-tab-content datex-active"
                    data-index="0"
                  >
                    {' '}
                    <i
                      className="icon_clock"
                      style={{
                        fontSize: '15px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    Mon - Fri / 08:00 - 18:00
                    <div
                      style={{
                        marginLeft: '12px',
                        marginRight: '17px',
                        display: 'inline',
                      }}
                    >
                      {/* {' '}
                      | */}
                    </div>
                    {/* <i
                      className="fa fa-envelope-open"
                      style={{
                        fontSize: '14px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    <a href="mailto:info@datexcargo.com">info@datexcargo.com</a>
                    <div
                      style={{
                        marginLeft: '12px',
                        marginRight: '17px',
                        display: 'inline',
                      }}
                    >
                      |
                    </div>
                    <i
                      className="fa fa-phone"
                      style={{
                        fontSize: '15px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    +91-84014-73108 */}
                  </div>
                  <div
                    className="datex-dropdown-tab-content "
                    data-index="1"
                  >
                    {' '}
                    <i
                      className="icon_clock"
                      style={{
                        fontSize: '15px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    Mon - Fri / 08:00 - 18:00
                    <div
                      style={{
                        marginLeft: '12px',
                        marginRight: '17px',
                        display: 'inline',
                      }}
                    >
                      |
                    </div>
                    <i
                      className="fa fa-envelope-open"
                      style={{
                        fontSize: '14px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    <a href="mailto:info@datexcargo.com">info@datexcargo.com</a>
                    <div
                      style={{
                        marginLeft: '12px',
                        marginRight: '17px',
                        display: 'inline',
                      }}
                    >
                      |
                    </div>
                    <i
                      className="fa fa-phone"
                      style={{
                        fontSize: '15px',
                        color: '#fff',
                        marginRight: '10px',
                      }}
                    ></i>
                    +44-324-345-67
                  </div>
                </div>
              </div>
            </div>
            <a
              className="datex-top-bar-right-button"
              href="/logisco/get-a-quote/"
              target="_self"
            >
              Get A Quote
            </a>
            <div className="datex-top-bar-right datex-item-pdlr">
              <div className="datex-top-bar-right-social">
                <a
                  href="/"
                  target="_blank"
                  className="datex-top-bar-social-icon"
                  title="facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="/"
                  target="_blank"
                  className="datex-top-bar-social-icon"
                  title="linkedin"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
                <a
                  href="/"
                  target="_blank"
                  className="datex-top-bar-social-icon"
                  title="twitter"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="/"
                  target="_blank"
                  className="datex-top-bar-social-icon"
                  title="instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopHeader1;
