import React from 'react';
import { Link } from 'react-router-dom';

class MobileHeader2 extends React.Component {
  constructor() {
    super();
  }

  render() {
    if (!this.props.isMobileMenuOpen) {
      return null;
    }
    return (
      <div>
        <div className={this.props.isMobileMenuOpen ? 'mobile-menu' : 'mobile-menu-off'} style={{ backgroundColor: '#1f1f1f' }}>
          <div className="mobile-menu-hamberger">
            <a
              className="datex-mm-menu-button datex-mobile-menu-button datex-mobile-button-hamburger"
              onClick={this.props.toggleMenu}
            // href="#datex-mobile-menu"
            >
              <span></span>
            </a>
          </div>
          <div>
            <ul id="menu-main-navigation-1" className="mobile-nav" style={{ height: window.innerHeight }}>
              <li>
                <Link to="/" onClick={this.props.toggleMenu}>Home</Link>
              </li>
              <li>
                <Link to="/services" onClick={this.props.toggleMenu}>Services</Link>
              </li>
              <li>
                <Link to="/tracking-status" onClick={this.props.toggleMenu}>Track</Link>
              </li>
              <li>
                <Link to="/aboutus" onClick={this.props.toggleMenu}>About us</Link>
              </li>
              <li>
                <Link to="/contactus" onClick={this.props.toggleMenu}>Contact us</Link>
              </li>
              {/* <li>
              <Link to="/enquiry">Enquiry</Link>
            </li> */}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileHeader2;
